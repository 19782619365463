#facebook-btn{
    background-color: #1877F2;
    font-family: 'Roboto', sans-serif;
    color: white;
    display: none;
}

.form-control {
    width: 75%;
    margin: 5px 0px !important;
}

.gray-container-box {
    background-color: lightgrey !important;
    height: 65vh;
    width: 50vw;
    margin: auto;
    border-radius: 5px;
    padding-bottom: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 15%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)
}

h3 {
    color: rgb(80,80,80);
    padding-top: 10px;
    text-align: center;
    margin-bottom: 2rem;
    letter-spacing: 1px;
}

hr {
    width: 80%;
}

img {
    height: 1.75rem;
    margin-right: 5px;
}

.login-btn-text {
    font-size: 0.9375rem;
}

@media only screen and (max-width: 500px){
    .gray-container-box {
        width: 75vw;
        height: 65vh;
        margin-top: -3vh;
    }
    .login-box {
        margin-top: 15px;
    }

    #log-in-btn {
        margin: 40px auto 10px;
        max-width: 60vw;
    }
    
    .login-btn-text, .signup-btn-text {
        margin-top: 2px;
        font-size: inherit;
    }

    .login-container {
        height: 50vh;
    }

    #sign-up-btn {
        margin: 60px auto 5px;
        max-width: 60vw;
    }

    .signup-box {
        margin-top: 15px;
    }
}