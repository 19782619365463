@import url('./styles/button.css');
@import url('./styles/dialog.css');

*{
  margin: 0;
  padding: 0;
}

  body {
    /* background-color: #073763ff;
    background-color: #163d61; */
    background-image: linear-gradient(to right, #073763f6, #073763f6), url('../Images/james-lee-Mfjq1hi-lVg-unsplash.jpg');
    background-size: cover;
    height: 100vh;
    min-width: 330px;
    min-height: 100vh;
  }
  .flex-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .error {
      display: block;
      color: red;
      font-size: small;
      margin: 0 0 15px 0;
      width: 75%;
      height: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }

  h1 {
    font-size: 40px;
  }
  
  .pageTitle{
    color:#ffe599;
    text-align: right;
    margin-right: 200px;
    margin-bottom: 45px;
    margin-top: 15px;
    font-size: 40px;
    text-shadow: 2px 2px 5px rgb(66 66 66 / 70%);
  }

  textarea {
    padding: 3px;
    font-family: cursive;
    background-color: #f3e193;
    resize: none;
  }

  textarea:focus {
    outline-color: gray;
    outline-width: thin;
    outline-style: solid;
  }

  @media only screen and (max-width: 500px){
    h1{
      font-size: 36px;
    }
          
    .pageTitle {
      margin-right: 50px;
    }
  }