.add-bar {
    background-color: whitesmoke;
    min-height: 65px;
    height: fit-content;
    bottom: 0;
    position: fixed;
    width: 100%;
    color: rgb(135,135, 135);
    padding-top: 5px;
    font-weight: bold;
    box-shadow: 13px -1px 8px #5a5a5a;
  }

.add-sign {
    text-align: center;
}

[chapteropen="true"] {
    display: inline-block;
}
  
[chapteropen="false"] {
    display: none;
}

.droppable-div{
    min-width: fit-content;
    width: 65%;
}

#cookbook {
    margin-bottom: 65px;
}

#customContextMenu {
  position: absolute;
  z-index: 50;
}

#empty-search-results{
    color: antiquewhite;
    margin-left: 50px;
    margin-top: 50px;
    font-weight: 500;
    font-size: large;
    font-style: italic;
}

[itemType="chapter"] {
    list-style: inside;
    list-style-type: "+ ";
    font-size: larger;
    margin-left: 20px;
    font-weight: bold;
    width: fit-content;
    white-space: nowrap;
    cursor: pointer;
  }
  
/* itemType="chapter" override */
[chapterexpanded="true"] {
    list-style-type: "- ";
}
  
[chapterexpanded="none"] {
    list-style-type: none;
    margin-left: 35px;
}

.MuiOutlinedInput-root {
    background-color: #f5f5f5b0;
}

[notesopen=false] {
    display:none;
}
  
[notesopen=true] {
    display: block;
} 

.MuiInputAdornment-root.search-icon.MuiInputAdornment-positionStart {
    color: #999;
}

#search-bar {
    margin-right: 35px;
    border-radius: 5px;
    border-width: inherit;
    padding: 3px 5px 3px 10px;
    display: block;
    text-align: right;
}
  
#search-bar input {    
    border-radius: 5px;
    height: 18px;
}

#search-container {
    background-color: whitesmoke;
}

ul[display=false] {
    display: none;
}

ul[display=true] {
    display: inline-block;
}

@media only screen and (max-width: 500px){
    #cookbook {
        padding-bottom: 60px;
    }

    #search-bar {
        margin: auto;
        text-align: center;
        margin-bottom: -1vh;
    }
}