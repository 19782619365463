#delete-cancel, #rename-cancel {
    background-color: white;
  }
  
#delete-cancel:hover, #rename-cancel:hover {
    background-color: #f3f3f3;
}

#delete-save, #rename-save {
    background-color: #ffd966ff;
}

#rename-box, #delete-box {
    color: rgb(80,80,80);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}

.subtext{
    font-size: small;
    font-style: italic;
}
