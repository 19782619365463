.cookbook-notes {
    margin: 3px 0px 0px 55px;
    width: 80vw;
    height: 75px;
    border-radius: 3px;
    color: #333;
    font-size: 18px;
}
  
[itemType="recipe"]:hover {
    cursor: pointer;
}
  
[itemType="recipe"] {
    list-style: inside;
    list-style-type: none;
    font-size: medium;
    margin-top: 5px;
    margin-left: 55px;
    display: inline;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
}  

.no-link-decoration{
    text-decoration: none;
    color: inherit;
}

.recipe{
    outline: none;
    width: fit-content;
    white-space: nowrap;
}

.recipe-icon {
    width: 14px;
    margin-left: 5px;
    filter: invert(1);
    vertical-align: text-bottom;
    transition: scale 200ms;
}
  
.recipe-icon:hover {
    cursor: pointer;
    transform: scale(1.05)

}

[recipetype="iframe"] {
    color:#ffd966ff;
}
  
[recipetype="new_window"] {
    color:#ffd966ff;
    text-decoration: underline;
}